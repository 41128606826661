import { useTranslation } from "react-i18next";
import Header from "./components/header";
import About from "./components/about-competition";
import OurTrack from "./components/our-track";
import EventAgenda from "./components/event-agenda";
import AgendaGuests from "./components/agenda-guests";
import Partners from "./components/partners";
import Judges from "./components/judges";
import Awards from "./components/awards";

import homeGapBg from "./assets/imgs/home-gap.png";
import platformIcon from "./assets/imgs/platform.svg";
import timeIcon from "./assets/imgs/time.svg";
import themeIcon from "./assets/imgs/theme.svg";
import awardIcon from "./assets/imgs/award.svg";
import blurCircle from "./assets/imgs/bg-card.png";
import { useCallback } from "react";

function App() {
  const { t, i18n } = useTranslation();

  const handleApply = useCallback(() => {
    if (i18n.language === "zh") {
      window.open("https://www.wenjuan.com/s/UZBZJvhl14/");
    } else {
      window.open(
        "https://docs.google.com/forms/d/1Cd_Ow5HSLWe3L01YASphk3LGuafi4Qun1mpjqar8QSI"
      );
    }
  }, [i18n]);

  return (
    <div className="App">
      <Header />
      <div
        className="
        container
        mx-auto
        flex
        justify-center
        flex-col
        items-center
        py-20 
        gap-7"
      >
        <img
          src={blurCircle}
          alt="blur"
          className="absolute left-0 w-[1000px] pointer-events-none select-none"
        />
        <h1 className="font-[Baunk] text-5xl">NFT Builder Competition</h1>
        <p className="font-[300] text-lg">{t("hero.desc")}</p>

        <button
          className="
            px-6
            py-2.5
            bg-[#00F363]
            text-black
            font-bold
            text-lg
            leading-tight
            uppercase
            rounded-full
            shadow-md
            hover:bg-[#00F363] hover:shadow-lg
            focus:bg-[#00F363] focus:shadow-lg focus:outline-none focus:ring-0
            active:bg-[#00F363] active:shadow-lg 
            transition
            duration-150
            ease-in-out
            flex
            items-center
            whitespace-nowrap
          "
          onClick={handleApply}
        >
          {t("hero.apply")}
        </button>
      </div>

      <div
        className="w-full h-[215px]"
        style={{ backgroundImage: `url(${homeGapBg})` }}
      />

      <div
        className="py-20"
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(149, 252, 192, 0.2) 0%, rgba(166, 255, 203, 0) 100%)",
        }}
      >
        <div className="container mx-auto flex justify-between">
          <div className="flex flex-col gap-5 h-[180px]">
            <img
              src={platformIcon}
              alt="platformIcon"
              className="select-none pointer-events-none h-[64px]"
            />

            <div
              className="w-[140px] h-[2px]"
              style={{
                backgroundImage:
                  "linear-gradient(270deg, rgba(217, 255, 234, 0) 0%, #68B88C 50.72%, rgba(211, 255, 231, 0) 100%)",
              }}
            />

            <div className="text-center">
              <div className="uppercase font-bold">{t("hero.platform")}</div>
              <div className="font-bold text-[#71B790]">YouTube</div>
            </div>
          </div>

          <div className="flex flex-col gap-5 h-[180px]">
            <img
              src={timeIcon}
              alt="timeIcon"
              className="select-none pointer-events-none h-[64px]"
            />

            <div
              className="w-[140px] h-[2px]"
              style={{
                backgroundImage:
                  "linear-gradient(270deg, rgba(217, 255, 234, 0) 0%, #68B88C 50.72%, rgba(211, 255, 231, 0) 100%)",
              }}
            />

            <div className="text-center">
              <div className="uppercase font-bold">{t("hero.time")}</div>
              <div className="font-bold text-[#71B790]">08.01-08.30</div>
            </div>
          </div>

          <div className="flex flex-col gap-5 h-[180px]">
            <img
              src={themeIcon}
              alt="themeIcon"
              className="select-none pointer-events-none h-[64px]"
            />

            <div
              className="w-[140px] h-[2px]"
              style={{
                backgroundImage:
                  "linear-gradient(270deg, rgba(217, 255, 234, 0) 0%, #68B88C 50.72%, rgba(211, 255, 231, 0) 100%)",
              }}
            />

            <div className="text-center">
              <div className="uppercase font-bold">{t("hero.theme")}</div>
              <div className="font-bold text-[#71B790]">
                {t("hero.nft_future")}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5 h-[180px]">
            <img
              src={awardIcon}
              alt="awardIcon"
              className="select-none pointer-events-none h-[64px]"
            />

            <div
              className="w-[140px] h-[2px]"
              style={{
                backgroundImage:
                  "linear-gradient(270deg, rgba(217, 255, 234, 0) 0%, #68B88C 50.72%, rgba(211, 255, 231, 0) 100%)",
              }}
            />

            <div className="text-center">
              <div className="uppercase font-bold">{t("hero.award")}</div>
              <div className="font-bold text-[#71B790]">
                {t("hero.award_label")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <About />
      <OurTrack />
      <EventAgenda />
      <Awards />
      <AgendaGuests />
      <Judges />
      <Partners />

      <div
        className="w-full h-[300px]"
        style={{
          backgroundImage:
            "linear-gradient(360deg, rgba(0, 244, 110, 0.2) 0%, rgba(0, 244, 110, 0) 100%)",
        }}
      ></div>
    </div>
  );
}

export default App;
