import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../dropdown";

export default function Header() {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      window.localStorage.setItem("language", language);
    },
    [i18n]
  );

  return (
    <div className="container mx-auto">
      <div className="flex justify-end gap-8 h-20 items-center">
        <button
          onClick={() =>
            document
              .getElementById("about")
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          {t("header.about")}
        </button>
        <button
          onClick={() =>
            document
              .getElementById("track")
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          {t("header.track")}
        </button>
        <button
          onClick={() =>
            document
              .getElementById("event_agenda")
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          {t("header.event_agenda")}
        </button>
        <button
          onClick={() =>
            document
              .getElementById("guests")
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          {t("header.guest")}
        </button>
        <button
          onClick={() =>
            document
              .getElementById("partners")
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          {t("header.partners")}
        </button>
        <Dropdown
          text={t("header.languages")}
          options={[
            {
              text: t("header.chinese"),
              onClick: () => handleChangeLanguage("zh"),
            },
            {
              text: t("header.english"),
              onClick: () => handleChangeLanguage("en"),
            },
          ]}
        />
        <Dropdown
          text={t("header.links")}
          options={[
            {
              text: "Telegram",
              onClick: () => window.open("https://t.me/nftbuildcompetition"),
            },
            {
              text: "Discord",
              onClick: () => window.open("https://discord.gg/QfnJp6VqwZ"),
            },
            {
              text: "Twitter",
              onClick: () => window.open("https://twitter.com/ChainIde"),
            },
            {
              text: "Youtube",
              onClick: () =>
                window.open(
                  "https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg"
                ),
            },
          ]}
        />
      </div>
    </div>
  );
}
