import { useTranslation } from "react-i18next";
import sinceImage from "../../assets/imgs/since-bg.png";

export default function About() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto" id="about">
      <div className="">
        <h3 className="font-bold text-3xl mb-6 legend-title">
          {t("about.title")}
        </h3>
        <div
          className="text-white p-8"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(146, 177, 155, 0.873) -1.63%, rgba(0, 0, 0, 0.585) 82.34%, rgba(0, 0, 0, 0.126) 124.33%), url(${sinceImage})`,
          }}
        >
          <p>
            <span className="font-[Baunk] text-3xl mr-2">
              {t("about.since")}
            </span>
            {t("about.desc")}
          </p>
          <p className="mt-2">{t("about.desc2")}</p>
        </div>
      </div>
    </div>
  );
}
