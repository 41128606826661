import { useTranslation } from "react-i18next";

export default function AgendaGuests() {
  const { t } = useTranslation();
  const users = [
    {
      avatar: require("../../assets/imgs/users/wuxiao.png"),
      name: "Xiao Wu",
    },
    {
      avatar: require("../../assets/imgs/users/tanghan.jpg"),
      name: "Han Tang",
    },
    {
      avatar: require("../../assets/imgs/users/caiwei.png"),
      name: "Wei Cai",
    },
    {
      avatar: require("../../assets/imgs/users/moon.jpg"),
      name: "Moon",
    },
    {
      avatar: require("../../assets/imgs/users/daniel.png"),
      name: "Daniel",
    },
    {
      avatar: require("../../assets/imgs/users/runchen.jpg"),
      name: "Runchen",
    },
  ];
  return (
    <div className="container mx-auto mt-20" id="guests">
      <h3 className="font-bold text-3xl mb-6 legend-title">
        {t("guests.title")}
      </h3>

      <div className="flex justify-between w-[80%] mx-auto mt-6">
        {users.map((user, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center gap-4"
          >
            <img
              src={user.avatar}
              alt={user.name}
              className="w-[120px] border border-black rounded-full"
            />
            <span className="font-bold">{user.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
