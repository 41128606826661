import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import Timeline from "../timeline";

export default function EventAgenda() {
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(
    "05/08/2022 20:00-21:45 GMT+8"
  );

  const [eventsDate, setEventsDate] = useState<any>([
    "05/08/2022 20:00-21:45 GMT+8",
    "05/08/2022-20/08/2022 23:59 GMT+8",
    "20/08/2022-25/08/2022 23:59 GMT+8",
    "26/08/2022 20:00-21:30 GMT+8",
    "30/08/2022 18:00 GMT+8",
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [events, setEvents] = useState<any>({
    "05/08/2022 20:00-21:45 GMT+8": [
      {
        time: "20:00 -- 20:05",
        title: "Opening Ceremony",
      },
      {
        time: "20:05 -- 20:35",
        title: "Matrix Labs",
      },
      {
        time: "20:35 -- 20:50",
        title: "Mono Cats",
      },
      {
        time: "20:50 -- 21:20",
        title: "NFT: Past,Present,and Future",
      },
    ],
    "05/08/2022-20/08/2022 23:59 GMT+8":
      "NFT Project Submission(recommend + apply)",
    "20/08/2022-25/08/2022 23:59 GMT+8": "NFT Project Review",
    "26/08/2022 20:00-21:30 GMT+8": "NFT Projects Demoday",
    "30/08/2022 18:00 GMT+8": "Announcement of Awards",
  });

  const handleSelectedDate = useCallback((val: string) => {
    setSelectedDate(val);
  }, []);

  useEffect(() => {
    if (i18n.language === "zh") {
      setEventsDate([
        "2022.08.05丨20:00 - 21:35 GMT+8",
        "2022.08.05 - 2022.08.20丨23:59 GMT+8",
        "2022.08.21- 2022.08.25 丨23:59 GMT+8",
        "2022.08.26丨20:00- 21:30 GMT+8",
        "2022.08.30 丨18:00 GMT+8",
      ]);
      setEvents({
        "2022.08.05丨20:00 - 21:35 GMT+8": [
          {
            time: "20:00 -- 20:05",
            title: "Opening Ceremony",
          },
          {
            time: "20:05 -- 20:35",
            title: "Matrix Labs",
          },
          {
            time: "20:35 -- 20:50",
            title: "Mono Cats",
          },
          {
            time: "20:50 -- 21:20",
            title: "NFT: Past,Present,and Future",
          },
        ],
        "2022.08.05 - 2022.08.20丨23:59 GMT+8":
          "NFT Project Submission(recommend + apply)",
        "2022.08.21- 2022.08.25 丨23:59 GMT+8": "NFT Project Review",
        "2022.08.26丨20:00- 21:30 GMT+8": "NFT Projects Demoday",
        "2022.08.30 丨18:00 GMT+8": "Announcement of Awards",
      });
      setSelectedDate("2022.08.05丨20:00 - 21:35 GMT+8");
    } else {
      setEventsDate([
        "05/08/2022 20:00-21:45 GMT+8",
        "05/08/2022-20/08/2022 23:59 GMT+8",
        "20/08/2022-25/08/2022 23:59 GMT+8",
        "26/08/2022 20:00-21:30 GMT+8",
        "30/08/2022 18:00 GMT+8",
      ]);
      setEvents({
        "05/08/2022 20:00-21:45 GMT+8": [
          {
            time: "20:00 -- 20:05",
            title: "Opening Ceremony",
          },
          {
            time: "20:05 -- 20:35",
            title: "Matrix Labs",
          },
          {
            time: "20:35 -- 20:50",
            title: "Mono Cats",
          },
          {
            time: "20:50 -- 21:20",
            title: "NFT: Past,Present,and Future",
          },
        ],
        "05/08/2022-20/08/2022 23:59 GMT+8":
          "NFT Project Submission(recommend + apply)",
        "20/08/2022-25/08/2022 23:59 GMT+8": "NFT Project Review",
        "26/08/2022 20:00-21:30 GMT+8": "NFT Projects Demoday",
        "30/08/2022 18:00 GMT+8": "Announcement of Awards",
      });
      setSelectedDate("05/08/2022 20:00-21:45 GMT+8");
    }
  }, [i18n.language]);

  return (
    <div className="container mx-auto mt-20" id="event_agenda">
      <h3 className="font-bold text-3xl mb-6 legend-title">
        {t("event_agenda.title")}
      </h3>
      <div className="p-2 rounded-full border border-black flex justify-between">
        {eventsDate.map((item: any) => (
          <button
            key={item}
            className={cx("rounded-full py-2 px-6", {
              "bg-black text-white": selectedDate === item,
            })}
            onClick={() => handleSelectedDate(item)}
          >
            {item}
          </button>
        ))}
      </div>

      <div className="mt-6 border-2 border-black rounded-lg px-[150px] py-10">
        {Array.isArray(events[selectedDate]) ? (
          <Timeline options={events[selectedDate] || []} />
        ) : (
          <p className="mx-[-130px]">{events[selectedDate]}</p>
        )}
      </div>
    </div>
  );
}
