import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import isEqual from "lodash/isEqual";

import playIcon from "../../assets/play.svg";

export default function Awards() {
  const { t, i18n } = useTranslation();
  const items = useMemo(
    () => [
      {
        title: t("awards.creative"),
        desc: "Web3 Secret Club",
        poster: "/images/secret.png",
        video:
          "https://matrixlabs-chainide-static-resource.s3.us-west-2.amazonaws.com/Web3+Secret+Club.mp4",
      },
      {
        title: t("awards.design"),
        desc: "MM3 NFT",
        poster: "/images/mm3.png",
        video:
          "https://matrixlabs-chainide-static-resource.s3.us-west-2.amazonaws.com/MM3+NFT.mp4",
      },
      {
        title: t("awards.application"),
        desc: "Viide",
        poster: "/images/viide.png",
        video:
          "https://matrixlabs-chainide-static-resource.s3.us-west-2.amazonaws.com/Viide.mp4",
      },
      {
        title: t("awards.potential"),
        desc: "wl.xyz",
        poster: "/images/wl.png",
        video:
          "https://matrixlabs-chainide-static-resource.s3.us-west-2.amazonaws.com/wl.xyz.mp4",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );
  const [selectedItem, setSelectedItem] = useState<any>(items[0]);
  return (
    <div className="container mx-auto mt-20">
      <h3 className="font-bold text-3xl mb-6 legend-title">
        {t("awards.title")}
      </h3>
      <div className="flex gap-20">
        <div className="flex-1">
          <video
            className="rounded w-full"
            poster={selectedItem.poster}
            controls
            src={selectedItem.video}
          >
            <source src={selectedItem.video} type="video/mp4"></source>
          </video>
        </div>
        <ol className="border-l border-black flex-1 h-auto flex flex-col justify-center gap-2">
          {items.map((item, index) => (
            <li
              key={index}
              className="cursor-pointer"
              onClick={() => setSelectedItem(item)}
            >
              <div className="flex flex-start items-center pt-3">
                <div
                  className={cx(
                    "border border-1 border-black bg-white w-2 h-2 rounded-full -ml-1 mr-3",
                    { "bg-black": isEqual(selectedItem, item) }
                  )}
                />
              </div>
              <div className="mt-[-15px] ml-4 mb-4">
                <h4
                  className={cx("font-semibold text-xl", {
                    "text-black": isEqual(selectedItem, item),
                    "text-gray-500": !isEqual(selectedItem, item),
                  })}
                >
                  {item.title}
                </h4>
                {isEqual(selectedItem, item) && (
                  <div className="font-thin mb-3 flex gap-2">
                    <img src={playIcon} className="w-[1.2em]" alt="icon" />
                    {item.desc}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
