import whitematrixIcon from "../../assets/imgs/whitematrix.svg";
import chaindieIcon from "../../assets/imgs/chainide.svg";
import matrixmarketIcon from "../../assets/imgs/matrixmarket.svg";
import matrixlabsIcon from "../../assets/imgs/matrixlabs.svg";
import awsIcon from "../../assets/imgs/aws.png";
import matrixIcon from "../../assets/imgs/matrix.png";
import seedaoIcon from "../../assets/imgs/seedao.png";
import lionIcon from "../../assets/imgs/lion.png";
import metopiaIcon from "../../assets/imgs/metopia.png";
import monocatsIcon from "../../assets/imgs/monocats.svg";
import monoIcon from "../../assets/imgs/mono.png";

export default function Partners() {
  const partners = [
    {
      icon: whitematrixIcon,
      name: "White Matrix",
    },
    {
      icon: chaindieIcon,
      name: "ChainIDE",
    },
    {
      icon: awsIcon,
      name: "AWS",
    },
    {
      icon: matrixmarketIcon,
      name: "Matrix Market",
    },
    {
      icon: matrixlabsIcon,
      name: "MatrixLabs",
    },
    {
      icon: matrixIcon,
      name: "Matrix",
    },
    {
      icon: seedaoIcon,
      name: "Seedao",
    },
    {
      icon: lionIcon,
      name: "Lion",
    },
    {
      icon: metopiaIcon,
      name: "metopia",
    },
    {
      icon: monocatsIcon,
      name: "monocats",
    },
    {
      icon: monoIcon,
      name: "mono",
    },
  ];
  return (
    <div className="mt-20 container mx-auto" id="partners">
      <h3 className="font-bold text-3xl mb-6 legend-title">Partners</h3>
      <div className="grid gap-8 grid-cols-6">
        {partners.map((item, index) => (
          <div
            className="border border-[#ABB2AD] h-[160px] w-[160px] rounded-2xl p-2 flex items-center justify-center  cursor-pointer"
            key={index}
          >
            <img
              src={item.icon}
              alt={item.name}
              className="pointer-events-none select-none"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
