import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import cx from "classnames";

import "swiper/css";

export default function Judges() {
  const { t, i18n } = useTranslation();
  const items = [
    {
      name: "Xiao Wu",
      img: require("../../assets/imgs/users/wuxiao.png"),
      position: "Founder of ChainIDE，CEO of WhiteMatrix",
      nameCN: "吴啸",
      positionCN: "ChainIDE创始人、纯白矩阵CEO",
      desc: "we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ",
    },
    {
      name: "Han Tang",
      img: require("../../assets/imgs/users/tanghan.jpg"),
      position: "Founder of SeeDAO",
      nameCN: "唐晗",
      positionCN: "SeeDAO创始人",
      desc: "we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ",
    },
    {
      name: "Wei Cai",
      img: require("../../assets/imgs/users/caiwei.png"),
      position:
        "Assistant Professor, The Chinese University of Hong Kong (Shenzhen)",
      nameCN: "蔡玮",
      positionCN: "香港中文大学（深圳）教授",
      desc: "we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ,we can introduce him ,what do you think ",
    },
  ];
  return (
    <div className="mt-20">
      <div className="container mx-auto">
        <h3 className="font-bold text-3xl mb-6 legend-title">
          {t("judges.title")}
        </h3>
      </div>
      <div className="container mx-auto">
        <Swiper
          slidesPerView={3}
          spaceBetween={60}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="p-2 rounded-2xl border border-black flex flex-col gap-2">
                <div>{i18n.language === "en" ? item.name : item.nameCN}</div>
                <div className="w-full">
                  <img src={item.img} alt="avatar" className="w-full" />
                </div>
                <div
                  className={cx("bg-[#F2F9EE] rounded-lg p-2", {
                    "h-[64px]": i18n.language === "en",
                  })}
                >
                  {i18n.language === "en" ? item.position : item.positionCN}
                </div>

                {/* <div className="bg-[#F2F9EE] rounded-lg p-2">{item.desc}</div> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
