/* eslint-disable jsx-a11y/anchor-is-valid */

import { useCallback, useState, useRef } from "react";
import { useClickAway } from "ahooks";

import cx from "classnames";

interface IOption {
  text: string;
  onClick: () => void;
}

interface IProps {
  text: string;
  options: IOption[];
}

export default function Dropdown(props: IProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  useClickAway(() => {
    setVisible(false);
  }, ref);

  return (
    <div className="flex justify-center" ref={ref}>
      <div>
        <div className="dropdown relative">
          <button
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="
              flex
              items-center
              whitespace-nowrap
            "
            onClick={toggleVisible}
          >
            {props.text}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              className="w-2 ml-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
              />
            </svg>
          </button>
          <ul
            className={cx(
              `
              dropdown-menu
              right-0
              min-w-max
              absolute
              bg-white
              text-base
              z-50
              float-left
              py-2
              list-none
              text-left
              rounded-lg
              shadow-lg
              mt-1
              m-0
              bg-clip-padding
              border-none
            `,
              { hidden: !visible }
            )}
            aria-labelledby="dropdownMenuButton1"
          >
            {props.options.map((item, index) => (
              <li key={index}>
                <a
                  className="
                    dropdown-item
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-gray-700
                    hover:bg-gray-100
                  "
                  href="#"
                  onClick={item.onClick}
                >
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
