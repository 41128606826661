export const en = {
  header: {
    about: "About",
    track: "Track",
    event_agenda: "Event Agenda",
    guest: "Guests/Judges",
    partners: "Partners",
    languages: "Languages",
    links: "Links",
    english: "English",
    chinese: "Chinese",
  },
  hero: {
    desc: "Dedicated to promoting and encouraging the integration and exchange 👋",
    apply: "Apply",
    platform: "Platform",
    time: "TIME",
    theme: "THEME",
    award: "AWARD",
    nft_future: "NFT : Past,Present,and Future",
    award_label: "Four Awards",
  },
  about: {
    title: "About the Competition",
    since: "Since",
    desc: "2021, NFT has rapidly emerged as the hottest track in the entire blockchain industry with some super high priced products and celebrity effects, and more and more organizations, celebrities, IPs and ordinary players are flocking to this emerging market. The best NFT ideas will be selected. The conference will select the best NFT idea award,The best NFT design award, the best NFT application award, and the best NFT potential award.",
    desc2:
      "We look forward to seeing every NFT builder and enthusiast join us!",
  },
  track: {
    title: "Our Awards",
    "01": {
      title: "Best NFT Creative Award",
      desc: "This award focuses on the design concept of the project, the narrative that attracts users, unique culture, bold and innovative ideas will be judged as additional points.",
    },
    "02": {
      title: "Best NFT Design Award",
      desc: "This award focuses on the design of the project, exquisite drawing style, unique artistic creation, and design in line with the times will be a plus point in the judging.",
    },
    "03": {
      title: "Best NFT Application Award",
      desc: "This award focuses on the empowerment value of the project, the online and offline utility design and empowerment innovation will be the extra points for judging.",
    },
    "04": {
      title: "Best NFT Potential Award",
      desc: "This award focuses on the project's conceptual creativity, design ability, application empowerment, community value and other aspects of comprehensive performance capabilities.",
    },
  },
  event_agenda: {
    title: "Event Agenda",
  },
  awards: {
    title: "Awards",
    creative: "The Best NFT Creative Award",
    design: "The Best NFT Design Award",
    application: "The Best NFT Application Award",
    potential: "The Best NFT Potential Award",
  },
  guests: {
    title: "Attending Guests",
  },
  judges: {
    title: "Judges",
  },
};
