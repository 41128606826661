export const zh = {
  header: {
    about: "关于",
    track: "赛道",
    event_agenda: "议程",
    guest: "嘉宾/评委",
    partners: "合作伙伴",
    languages: "语言",
    links: "链接",
    english: "英文",
    chinese: "中文",
  },
  hero: {
    desc: "致力于推动和鼓励来自全球各地的NFT建设者的融合交流 👋",
    apply: "申请",
    platform: "直播平台",
    time: "活动时间",
    theme: "大会主题",
    award: "奖励机制",
    nft_future: "NFT的未来发展方向",
    award_label: "4个赛道",
  },
  about: {
    title: "关于这次活动",
    since: "2021",
    desc: "年以来，NFT凭借一些超高售价产品和名人效应迅速出圈，成为整个区块链产业最热门的赛道，越来越多的机构、名人、IP以及普通玩家正在涌入这个新兴市场。NFT建设者大会致力于推动和鼓励来自全球各地的NFT建设者的融合交流，我们邀请业内专业人士与NFT建设者齐聚一堂，就未来NFT赛道的发展方向交换观点和专业意见。大会将会评选出最佳NFT创意奖、最佳NFT设计奖、最佳NFT应用奖以及最佳NFT潜力奖。！",
    desc2: "期待每一位NFT建设者和爱好者的加入",
  },
  track: {
    title: "我们的赛道",
    "01": {
      title: "最佳NFT创意奖",
      desc: "本奖项侧重项目的设计概念，吸引用户的叙事、独具一格的文化、大胆新颖的创意将成为评判的加分项。",
    },
    "02": {
      title: "最佳NFT设计奖",
      desc: "本奖项侧重项目的设计图稿，精致的画风、独特的艺术创造、与时代接轨的设计将成为评判的加分项。",
    },
    "03": {
      title: "最佳NFT应用奖",
      desc: "本奖项侧重项目的赋能价值，线上线下的效用设计与赋能创新将成为评判的加分项。",
    },
    "04": {
      title: "最佳NFT潜力奖",
      desc: "本奖项注重项目的概念创意、设计能力、应用赋能、社区价值等各方面综合表现能力。",
    },
  },
  event_agenda: {
    title: "大会议程",
  },
  awards: {
    title: "获奖作品",
    creative: "最佳NFT创意奖",
    design: "最佳NFT设计奖",
    application: "最佳NFT应用奖",
    potential: "最佳NFT潜力奖",
  },
  guests: {
    title: "嘉宾",
  },
  judges: {
    title: "评委",
  },
};
