export interface IOption {
  time: string;
  title: string;
  text?: string;
  images?: {
    name: string;
    img: string;
  }[];
}

export interface IProps {
  options: IOption[];
}

export default function Timeline({ options }: IProps) {
  return (
    <ol className="border-l border-black">
      {options.map((item) => (
        <li className="relative" key={item.time}>
          <p className="text-[#555] text-sm left-[-95px] top-1 absolute text-right">
            {item.time}
          </p>
          <div className="flex flex-start items-center pt-3">
            <div className="border border-black bg-white w-2 h-2 rounded-full -ml-1 mr-3"></div>
          </div>
          <div className="mt-0.5 ml-4 mb-6 relative top-[-20px]">
            <h4 className="text-[#333] font-semibold text-xl mb-1.5">
              {item.title}
            </h4>

            {item.images && (
              <div className="flex gap-6 mt-4">
                {item.images.map((img) => (
                  <div
                    key={img.name}
                    className="flex flex-col justify-center items-center gap-2"
                  >
                    <img
                      src={img.img}
                      alt="logo"
                      className="rounded-full w-[48px] border-2 border-black"
                    />
                    <span>{img.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </li>
      ))}
    </ol>
  );
}
