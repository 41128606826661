import { useTranslation } from "react-i18next";

export default function OurTrack() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-20" id="track">
      <h3 className="font-bold text-3xl mb-6 legend-title">
        {t("track.title")}
      </h3>

      <div className="w-full flex justify-end gap-10 relative">
        <div className="bg-[#DFFEEA] p-5 flex w-[35%]">
          <span className="font-[Baunk] text-black text-2xl gap-2">01</span>
          <div className="ml-2">
            <h3 className="text-lg font-bold">{t("track.01.title")}</h3>
            <p>{t("track.01.desc")}</p>
          </div>
        </div>

        <div className="bg-[#DFFEEA] p-5 flex w-[35%]">
          <span className="font-[Baunk] text-black text-2xl gap-2">02</span>
          <div className="ml-2">
            <h3 className="text-lg font-bold">{t("track.02.title")}</h3>
            <p>{t("track.02.desc")}</p>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-start gap-10 mt-5">
        <div className="bg-[#DFFEEA] p-5 flex w-[35%]">
          <span className="font-[Baunk] text-black text-2xl gap-2">03</span>
          <div className="ml-2">
            <h3 className="text-lg font-bold">{t("track.03.title")}</h3>
            <p>{t("track.03.desc")}</p>
          </div>
        </div>

        <div className="bg-[#DFFEEA] p-5 flex w-[35%]">
          <span className="font-[Baunk] text-black text-2xl gap-2">04</span>
          <div className="ml-2">
            <h3 className="text-lg font-bold">{t("track.04.title")}</h3>
            <p>{t("track.04.desc")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
